<template>
  <div class="modal">
    <div class="modal__content">
        <iframe
            :src="`${video.url}${video.id}${getQueryString(video.options)}`"
            frameborder="0"
            allow="autoplay"
            class="modal__video"
        />

      <button class="button -squaremodal__close" @click="closeModal">
        <Icon id="close"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  computed: {
    modal() {
      return this.$store.state.modal
    },
    video() {
      if(this.modal.video) {
        return {
          url: 'https://www.youtube.com/embed/',
          id: this.modal.video,
          options: { controls: 0, autoplay: 1}
        }
      }
      return null
    }
  },
  methods: {
    getQueryString(options) {
      return !options
          ? ''
          : `?${Object.entries(options)
              .map(keyValue => keyValue.join('='))
              .join('&')}`
    },
    closeModal() {
      this.$store.state.modal = null
    }
  }
}
</script>
