<template>
  <router-view/>
  <transition name="fade" appear>
    <Modal v-if="modal"/>
  </transition>
  <Gdpr/>
  <MessageDesk/>
</template>

<script>
import {useLangRouter} from "@/extensions/langRouter";
import MessageDesk from "@/components/MessageDesk.vue";
import Modal from "@/components/Modal.vue";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  components: {Gdpr, MessageDesk, Modal},
  setup() {
    useLangRouter()
  },
  computed: {
    modal() {
      return this.$store.state.modal
    },
  },
}
</script>
