import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

export default router
