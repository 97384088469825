<template>
  <button class="chatbot__button" id="chatbot__button" @click="appendScript">
    <span class="chatbot__button-text">{{ $t('message_desk') }}</span>
    <img class="chatbot__button-image" src="@/assets/message_desk/contact.png" alt="">
  </button>
</template>

<script>
export default {
  name: 'MessageDesk',
  methods: {
    appendScript() {
      let script = document.getElementById('chatbot')
      if (script) {
        script.remove()
      }

      script = document.createElement('script')
      script.id = 'chatbot'
      script.src = `https://api.snapdesk.app/chatbot?key=${process.env.VUE_APP_MESSAGE_DESK_KEY}`
      script.addEventListener('load', this.openScript)
      document.body.appendChild(script)
    },
    openScript() {
      setTimeout(() => {
        document.getElementById('msgdsk-chatbot-app').querySelector('button').click()
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.chatbot {
  &__button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: #e03216;
    z-index: 2;
    color: white;
    border-radius: 50vh;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0;
    white-space: break-spaces;
    transition: gap 0.25s ease;
    height: 6.125rem;
    cursor: pointer;
    border: none;

    &:hover {
      text-decoration: none;
      gap: 1rem;

      & .chatbot__button-text {
        width: 11.25rem;
        opacity: 1;
      }
    }

    &-image {
      width: 3.125rem;
    }

    &-text {
      display: block;
      width: 0;
      opacity: 0;
      overflow: hidden;
      transition: width 0.25s ease, opacity 0.25s ease;
      max-height: 3.125rem;
      font-size: 0.8125rem;
      line-height: 1.3;
    }
  }
}

.--hidden, .agent-clear-wrapper, .agent-header-wrapper, .agent-message-wrapper, button[name="widgetToggle"] {
  display: none !important;
}

.msgdsk-chat-wrapper {
  bottom: 0 !important;
  right: 0 !important;
}
</style>
